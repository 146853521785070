import { computed, isRef, ref, watch } from "vue";
import { createI18n } from "vue-i18n";
import { defineNuxtPlugin, useNuxtApp } from "#imports";
import {
  localeCodes,
  vueI18nConfigs,
  isSSG,
  hasPages,
  localeLoaders,
  parallelPlugin,
  normalizedLocales
} from "#build/i18n.options.mjs";
import { loadVueI18nOptions, loadLocale } from "../messages.js";
import { loadAndSetLocale, detectRedirect, navigate, extendBaseUrl } from "../utils.js";
import {
  getBrowserLocale,
  getLocaleCookie,
  setLocaleCookie,
  getI18nCookie,
  runtimeDetectBrowserLanguage,
  getDefaultLocaleForDomain,
  setupMultiDomainLocales,
  wrapComposable,
  defineGetter
} from "../internal.js";
import { createLocaleFromRouteGetter, resolveBaseUrl } from "../routing/utils.js";
import { extendI18n } from "../routing/i18n.js";
import { createLogger } from "virtual:nuxt-i18n-logger";
import { getI18nTarget } from "../compatibility.js";
import { resolveRoute } from "../routing/routing.js";
import { localeHead } from "../routing/head.js";
import { useLocalePath, useLocaleRoute, useRouteBaseName, useSwitchLocalePath, useLocaleLocation } from "../composables/index.js";
export default defineNuxtPlugin({
  name: "i18n:plugin",
  parallel: parallelPlugin,
  async setup(nuxt) {
    const logger = /* @__PURE__ */ createLogger("plugin:i18n");
    const nuxtApp = nuxt;
    const currentRoute = nuxtApp.$router.currentRoute;
    const defaultLocaleDomain = getDefaultLocaleForDomain(nuxtApp);
    setupMultiDomainLocales(nuxtApp, defaultLocaleDomain);
    const runtimeI18n = {
      ...nuxtApp.$config.public.i18n,
      defaultLocale: defaultLocaleDomain
    };
    nuxtApp.$config.public.i18n.defaultLocale = defaultLocaleDomain;
    runtimeI18n.baseUrl = extendBaseUrl();
    const _detectBrowserLanguage = runtimeDetectBrowserLanguage();
    __DEBUG__ && logger.log("isSSG", isSSG);
    __DEBUG__ && logger.log("useCookie on setup", _detectBrowserLanguage && _detectBrowserLanguage.useCookie);
    __DEBUG__ && logger.log("defaultLocale on setup", runtimeI18n.defaultLocale);
    const vueI18nOptions = await loadVueI18nOptions(vueI18nConfigs, useNuxtApp());
    vueI18nOptions.messages = vueI18nOptions.messages || {};
    vueI18nOptions.fallbackLocale = vueI18nOptions.fallbackLocale ?? false;
    if (defaultLocaleDomain) {
      vueI18nOptions.locale = defaultLocaleDomain;
    }
    const getRouteLocale = createLocaleFromRouteGetter();
    const localeCookie = getI18nCookie();
    const i18n = createI18n(vueI18nOptions);
    i18n.__firstAccess = true;
    i18n.__setLocale = (locale) => {
      const i = getI18nTarget(i18n);
      if (isRef(i.locale)) {
        i.locale.value = locale;
      } else {
        i.locale = locale;
      }
    };
    nuxtApp._vueI18n = i18n;
    extendI18n(i18n, {
      extendComposer(composer) {
        const _locales = ref(runtimeI18n.locales);
        const _localeCodes = ref(localeCodes);
        const _baseUrl = ref("");
        composer.locales = computed(() => _locales.value);
        composer.localeCodes = computed(() => _localeCodes.value);
        composer.baseUrl = computed(() => _baseUrl.value);
        if (import.meta.client) {
          watch(
            composer.locale,
            () => {
              _baseUrl.value = resolveBaseUrl(runtimeI18n.baseUrl, nuxtApp);
            },
            { immediate: true }
          );
        } else {
          _baseUrl.value = resolveBaseUrl(runtimeI18n.baseUrl, nuxtApp);
        }
        composer.strategy = runtimeI18n.strategy;
        composer.localeProperties = computed(
          () => normalizedLocales.find((l) => l.code === composer.locale.value) || { code: composer.locale.value }
        );
        composer.setLocale = async (locale) => {
          await loadAndSetLocale(locale, i18n.__firstAccess);
          if (composer.strategy === "no_prefix" || !hasPages) {
            await composer.loadLocaleMessages(locale);
            i18n.__setLocale(locale);
            return;
          }
          const route = currentRoute.value;
          const redirectPath = await nuxtApp.runWithContext(
            () => detectRedirect({ to: route, locale, routeLocale: getRouteLocale(route) })
          );
          __DEBUG__ && logger.log("redirectPath on setLocale", redirectPath);
          await nuxtApp.runWithContext(
            () => navigate({ nuxtApp, redirectPath, locale, route }, { enableNavigate: true })
          );
        };
        composer.loadLocaleMessages = async (locale) => await loadLocale(locale, localeLoaders, composer.mergeLocaleMessage.bind(composer));
        composer.differentDomains = runtimeI18n.differentDomains;
        composer.defaultLocale = runtimeI18n.defaultLocale;
        composer.getBrowserLocale = () => getBrowserLocale();
        composer.getLocaleCookie = () => getLocaleCookie(localeCookie, _detectBrowserLanguage, composer.defaultLocale);
        composer.setLocaleCookie = (locale) => setLocaleCookie(localeCookie, locale, _detectBrowserLanguage);
        composer.onBeforeLanguageSwitch = (oldLocale, newLocale, initialSetup, context) => nuxt.callHook("i18n:beforeLocaleSwitch", {
          oldLocale,
          newLocale,
          initialSetup,
          context
        });
        composer.onLanguageSwitched = (oldLocale, newLocale) => nuxt.callHook("i18n:localeSwitched", { oldLocale, newLocale });
        composer.finalizePendingLocaleChange = async () => {
          if (!i18n.__pendingLocale) return;
          i18n.__setLocale(i18n.__pendingLocale);
          i18n.__resolvePendingLocalePromise?.();
          i18n.__pendingLocale = void 0;
        };
        composer.waitForPendingLocaleChange = async () => {
          if (i18n.__pendingLocale && i18n.__pendingLocalePromise) {
            await i18n.__pendingLocalePromise;
          }
        };
      },
      extendComposerInstance(instance, c) {
        const props = [
          ["locales", () => c.locales],
          ["localeCodes", () => c.localeCodes],
          ["baseUrl", () => c.baseUrl],
          ["strategy", () => c.strategy],
          ["localeProperties", () => c.localeProperties],
          ["setLocale", () => async (locale) => Reflect.apply(c.setLocale, c, [locale])],
          ["loadLocaleMessages", () => async (locale) => Reflect.apply(c.loadLocaleMessages, c, [locale])],
          ["differentDomains", () => c.differentDomains],
          ["defaultLocale", () => c.defaultLocale],
          ["getBrowserLocale", () => () => Reflect.apply(c.getBrowserLocale, c, [])],
          ["getLocaleCookie", () => () => Reflect.apply(c.getLocaleCookie, c, [])],
          ["setLocaleCookie", () => (locale) => Reflect.apply(c.setLocaleCookie, c, [locale])],
          [
            "onBeforeLanguageSwitch",
            () => (oldLocale, newLocale, initialSetup, context) => Reflect.apply(c.onBeforeLanguageSwitch, c, [oldLocale, newLocale, initialSetup, context])
          ],
          [
            "onLanguageSwitched",
            () => (oldLocale, newLocale) => Reflect.apply(c.onLanguageSwitched, c, [oldLocale, newLocale])
          ],
          ["finalizePendingLocaleChange", () => () => Reflect.apply(c.finalizePendingLocaleChange, c, [])],
          ["waitForPendingLocaleChange", () => () => Reflect.apply(c.waitForPendingLocaleChange, c, [])]
        ];
        for (const [key, get] of props) {
          Object.defineProperty(instance, key, { get });
        }
      }
    });
    nuxt.vueApp.use(i18n);
    defineGetter(nuxtApp, "$i18n", getI18nTarget(i18n));
    return {
      provide: {
        /**
         * TODO: remove type assertions while type narrowing based on generated types
         */
        localeHead: wrapComposable(localeHead),
        localePath: useLocalePath(),
        localeRoute: useLocaleRoute(),
        getRouteBaseName: useRouteBaseName(),
        switchLocalePath: useSwitchLocalePath(),
        // TODO: remove in v10
        resolveRoute: wrapComposable(resolveRoute),
        // TODO: remove in v10
        localeLocation: useLocaleLocation()
      }
    };
  }
});
