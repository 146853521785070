<template>
    <NuxtLayout>
        <NuxtPage />
    </NuxtLayout>
</template>

<script lang="ts" setup>
import Intercom from '@intercom/messenger-js-sdk'

const { locale } = useI18n()
const $config = useRuntimeConfig()

useGeolocation()

useLocaleHead({})
useHead({
    htmlAttrs: {
        lang: locale.value,
    },
    title: 'Helping residential cleaners grow and scale their businesses | Augwa',
})

onMounted(() => {
    Intercom({
        app_id: $config.public.intercom.appId,
    })
})
</script>