import revive_payload_client_4sVQNw7RlN from "/home/runner/work/augwa/augwa/web/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/augwa/augwa/web/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/augwa/augwa/web/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_jtc2qNDx4l from "/home/runner/work/augwa/augwa/web/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/home/runner/work/augwa/augwa/web/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/runner/work/augwa/augwa/web/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/augwa/augwa/web/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/augwa/augwa/web/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_zlvi6dcIsi from "/home/runner/work/augwa/augwa/web/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/augwa/augwa/web/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_VdOeuw25Mb from "/home/runner/work/augwa/augwa/web/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_eX0iNocP1z from "/home/runner/work/augwa/augwa/web/node_modules/.cache/nuxt/.nuxt/sentry-client-config.mjs";
import plugin_client_UYiXMU8ZyN from "/home/runner/work/augwa/augwa/web/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import switch_locale_path_ssr_5csfIgkrBP from "/home/runner/work/augwa/augwa/web/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_VrpP1JaYg8 from "/home/runner/work/augwa/augwa/web/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_sq1MuCrqbC from "/home/runner/work/augwa/augwa/web/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import slideovers_X7whXyrIWR from "/home/runner/work/augwa/augwa/web/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_oqljskDzoR from "/home/runner/work/augwa/augwa/web/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_ETtiVmZNmO from "/home/runner/work/augwa/augwa/web/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_OVoKJro5pc from "/home/runner/work/augwa/augwa/web/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_WLsn00x1qh from "/home/runner/work/augwa/augwa/web/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import ssg_detect_3fHkBxLtv0 from "/home/runner/work/augwa/augwa/web/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_jtc2qNDx4l,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_zlvi6dcIsi,
  prefetch_client_5tzzN0oIVL,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_eX0iNocP1z,
  plugin_client_UYiXMU8ZyN,
  switch_locale_path_ssr_5csfIgkrBP,
  route_locale_detect_VrpP1JaYg8,
  i18n_sq1MuCrqbC,
  slideovers_X7whXyrIWR,
  modals_oqljskDzoR,
  colors_ETtiVmZNmO,
  plugin_client_OVoKJro5pc,
  plugin_WLsn00x1qh,
  ssg_detect_3fHkBxLtv0
]