import { joinURL, isEqual } from "ufo";
import { isString, isFunction, isObject } from "@intlify/shared";
import { navigateTo, useNuxtApp, useRouter, useRuntimeConfig, useState } from "#imports";
import { NUXT_I18N_MODULE_ID, isSSG, localeCodes, localeLoaders, normalizedLocales } from "#build/i18n.options.mjs";
import {
  detectBrowserLanguage,
  getLocaleDomain,
  getDomainFromLocale,
  runtimeDetectBrowserLanguage,
  getHost
} from "./internal.js";
import { loadLocale, makeFallbackLocaleCodes } from "./messages.js";
import { localePath, switchLocalePath } from "./routing/routing.js";
import { createLogger } from "virtual:nuxt-i18n-logger";
import { unref } from "vue";
import { createLocaleFromRouteGetter } from "./routing/utils.js";
export function initCommonComposableOptions(i18n) {
  return {
    i18n: i18n ?? useNuxtApp().$i18n,
    router: useRouter(),
    runtimeConfig: useRuntimeConfig(),
    metaState: useState("nuxt-i18n-meta", () => ({}))
  };
}
export async function loadAndSetLocale(newLocale, initial = false) {
  const logger = /* @__PURE__ */ createLogger("loadAndSetLocale");
  const nuxtApp = useNuxtApp();
  const { differentDomains, skipSettingLocaleOnNavigate } = nuxtApp.$config.public.i18n;
  const opts = runtimeDetectBrowserLanguage();
  const oldLocale = unref(nuxtApp.$i18n.locale);
  const localeCodes2 = unref(nuxtApp.$i18n.localeCodes);
  function syncCookie(locale = oldLocale) {
    if (opts === false || !opts.useCookie) return;
    if (skipSettingLocaleOnNavigate) return;
    nuxtApp.$i18n.setLocaleCookie(locale);
  }
  const localeOverride = await nuxtApp.$i18n.onBeforeLanguageSwitch(oldLocale, newLocale, initial, nuxtApp);
  if (localeOverride && localeCodes2.includes(localeOverride)) {
    if (oldLocale === localeOverride) {
      syncCookie();
      return false;
    }
    newLocale = localeOverride;
  }
  __DEBUG__ && logger.log({ newLocale, oldLocale, initial });
  if (!newLocale) {
    syncCookie();
    return false;
  }
  if (!initial && differentDomains) {
    syncCookie();
    return false;
  }
  if (oldLocale === newLocale) {
    syncCookie();
    return false;
  }
  const i18nFallbackLocales = unref(nuxtApp.$i18n.fallbackLocale);
  const setter = nuxtApp.$i18n.mergeLocaleMessage.bind(nuxtApp.$i18n);
  if (i18nFallbackLocales) {
    const fallbackLocales = makeFallbackLocaleCodes(i18nFallbackLocales, [newLocale]);
    await Promise.all(fallbackLocales.map((locale) => loadLocale(locale, localeLoaders, setter)));
  }
  await loadLocale(newLocale, localeLoaders, setter);
  if (skipSettingLocaleOnNavigate) {
    return false;
  }
  syncCookie(newLocale);
  nuxtApp._vueI18n.__setLocale(newLocale);
  await nuxtApp.$i18n.onLanguageSwitched(oldLocale, newLocale);
  return true;
}
export function detectLocale(route, routeLocale, currentLocale, localeCookie) {
  const nuxtApp = useNuxtApp();
  const { strategy, defaultLocale, differentDomains, multiDomainLocales } = nuxtApp.$config.public.i18n;
  const _detectBrowserLanguage = runtimeDetectBrowserLanguage();
  const logger = /* @__PURE__ */ createLogger("detectLocale");
  const detectedBrowser = detectBrowserLanguage(route, localeCookie, currentLocale);
  __DEBUG__ && logger.log({ detectBrowserLanguage: detectedBrowser });
  if (detectedBrowser.locale && detectedBrowser.from != null && localeCodes.includes(detectedBrowser.locale)) {
    return detectedBrowser.locale;
  }
  let detected = "";
  __DEBUG__ && logger.log("1/3", { detected, strategy });
  if (differentDomains || multiDomainLocales) {
    detected ||= getLocaleDomain(normalizedLocales, strategy, route);
  } else if (strategy !== "no_prefix") {
    detected ||= routeLocale;
  }
  __DEBUG__ && logger.log("2/3", { detected, detectBrowserLanguage: _detectBrowserLanguage });
  const cookieLocale = (localeCodes.includes(detectedBrowser.locale) || localeCookie && localeCodes.includes(localeCookie)) && _detectBrowserLanguage && _detectBrowserLanguage.useCookie && localeCookie;
  detected ||= cookieLocale || currentLocale || defaultLocale || "";
  __DEBUG__ && logger.log("3/3", { detected, cookieLocale, defaultLocale, localeCookie });
  return detected;
}
export function detectRedirect({ to, from, locale, routeLocale }, inMiddleware = false) {
  if (routeLocale === locale || useNuxtApp().$i18n.strategy === "no_prefix") {
    return "";
  }
  const common = initCommonComposableOptions();
  const logger = /* @__PURE__ */ createLogger("detectRedirect");
  __DEBUG__ && logger.log({ to, from });
  __DEBUG__ && logger.log({ locale, routeLocale, inMiddleware });
  let redirectPath = switchLocalePath(common, locale, to);
  if (inMiddleware && !redirectPath) {
    redirectPath = localePath(common, to.fullPath, locale);
  }
  if (isEqual(redirectPath, to.fullPath) || from && isEqual(redirectPath, from.fullPath)) {
    return "";
  }
  return redirectPath;
}
function isRootRedirectOptions(rootRedirect) {
  return isObject(rootRedirect) && "path" in rootRedirect && "statusCode" in rootRedirect;
}
const useRedirectState = () => useState(NUXT_I18N_MODULE_ID + ":redirect", () => "");
function _navigate(redirectPath, status) {
  return navigateTo(redirectPath, { redirectCode: status });
}
export async function navigate({ nuxtApp, locale, route, redirectPath }, { status = 302, enableNavigate = false } = {}) {
  const { rootRedirect, differentDomains, multiDomainLocales, skipSettingLocaleOnNavigate, locales, strategy } = nuxtApp.$config.public.i18n;
  const logger = /* @__PURE__ */ createLogger("navigate");
  __DEBUG__ && logger.log("options", {
    status,
    rootRedirect,
    differentDomains,
    skipSettingLocaleOnNavigate,
    enableNavigate,
    isSSG
  });
  if (route.path === "/" && rootRedirect) {
    if (isString(rootRedirect)) {
      redirectPath = "/" + rootRedirect;
    } else if (isRootRedirectOptions(rootRedirect)) {
      redirectPath = "/" + rootRedirect.path;
      status = rootRedirect.statusCode;
    }
    redirectPath = nuxtApp.$localePath(redirectPath, locale);
    __DEBUG__ && logger.log("rootRedirect mode", { redirectPath, status });
    return _navigate(redirectPath, status);
  }
  if (import.meta.client && skipSettingLocaleOnNavigate) {
    nuxtApp._vueI18n.__pendingLocale = locale;
    nuxtApp._vueI18n.__pendingLocalePromise = new Promise((resolve) => {
      nuxtApp._vueI18n.__resolvePendingLocalePromise = () => resolve();
    });
    if (!enableNavigate) {
      return;
    }
  }
  if (multiDomainLocales && strategy === "prefix_except_default") {
    const host = getHost();
    const currentDomain = locales.find((locale2) => {
      if (typeof locale2 !== "string") {
        return locale2.defaultForDomains?.find((domain) => domain === host);
      }
      return false;
    });
    const defaultLocaleForDomain = typeof currentDomain !== "string" ? currentDomain?.code : void 0;
    if (route.path.startsWith(`/${defaultLocaleForDomain}`)) {
      return _navigate(route.path.replace(`/${defaultLocaleForDomain}`, ""), status);
    } else if (!route.path.startsWith(`/${locale}`) && locale !== defaultLocaleForDomain) {
      const getLocaleFromRoute = createLocaleFromRouteGetter();
      const oldLocale = getLocaleFromRoute(route.path);
      if (oldLocale !== "") {
        return _navigate(`/${locale + route.path.replace(`/${oldLocale}`, "")}`, status);
      } else {
        return _navigate(`/${locale + (route.path === "/" ? "" : route.path)}`, status);
      }
    } else if (redirectPath && route.path !== redirectPath) {
      return _navigate(redirectPath, status);
    }
    return;
  }
  if (!differentDomains) {
    if (redirectPath) {
      return _navigate(redirectPath, status);
    }
  } else {
    const state = useRedirectState();
    __DEBUG__ && logger.log("redirect", { state: state.value, redirectPath });
    if (state.value && state.value !== redirectPath) {
      if (import.meta.client) {
        state.value = "";
        window.location.assign(redirectPath);
      } else if (import.meta.server) {
        __DEBUG__ && logger.log("differentDomains servermode", { redirectPath });
        state.value = redirectPath;
      }
    }
  }
}
function prefixable({ currentLocale, defaultLocale, strategy }) {
  return (
    // strategy has no prefixes
    strategy !== "no_prefix" && // strategy should not prefix default locale
    !(currentLocale === defaultLocale && (strategy === "prefix_and_default" || strategy === "prefix_except_default"))
  );
}
export function extendPrefixable(runtimeConfig = useRuntimeConfig()) {
  const logger = /* @__PURE__ */ createLogger("extendPrefixable");
  return (opts) => {
    __DEBUG__ && logger.log(prefixable(opts));
    return prefixable(opts) && !runtimeConfig.public.i18n.differentDomains;
  };
}
export function extendSwitchLocalePathIntercepter(runtimeConfig = useRuntimeConfig()) {
  const logger = /* @__PURE__ */ createLogger("extendSwitchLocalePathIntercepter");
  return (path, locale) => {
    if (!runtimeConfig.public.i18n.differentDomains) {
      return path;
    }
    const domain = getDomainFromLocale(locale);
    __DEBUG__ && logger.log({ domain, path });
    return domain && joinURL(domain, path) || path;
  };
}
export function extendBaseUrl() {
  const logger = /* @__PURE__ */ createLogger("extendBaseUrl");
  return () => {
    const ctx = useNuxtApp();
    const { baseUrl, defaultLocale, differentDomains } = ctx.$config.public.i18n;
    if (isFunction(baseUrl)) {
      const baseUrlResult = baseUrl(ctx);
      __DEBUG__ && logger.log("using localeLoader function -", { baseUrlResult });
      return baseUrlResult;
    }
    const localeCode = isFunction(defaultLocale) ? defaultLocale() : defaultLocale;
    if (differentDomains && localeCode) {
      const domain = getDomainFromLocale(localeCode);
      if (domain) {
        __DEBUG__ && logger.log("using differentDomains -", { domain });
        return domain;
      }
    }
    if (baseUrl) {
      __DEBUG__ && logger.log("using runtimeConfig -", { baseUrl });
      return baseUrl;
    }
    return baseUrl;
  };
}
export function getNormalizedLocales(locales) {
  const normalized = [];
  for (const locale of locales) {
    if (isString(locale)) {
      normalized.push({ code: locale });
      continue;
    }
    normalized.push(locale);
  }
  return normalized;
}
