export default defineAppConfig({
    ui: {
        primary: 'brand-primary',
        gray: 'brand-gray',
        table: {
            th: {
                color: 'text-primary-900',
            },
            td: {
                color: 'text-primary',
            },
        },
        formGroup: {
            label: {
                base: 'text-primary-700',
            },
        },
        button: {
            color: {
                secondary: '',
                success: '',
                danger: '',
                warning: '',
            },
        },
        alert: {
            color: {
                secondary: '',
                success: '',
                danger: '',
                warning: '',
            },
        },
        notification: {
            color: {
                secondary: '',
                success: '',
                danger: '',
                warning: '',
            },
        },
        notifications: {
            color: {
                secondary: '',
                success: '',
                danger: '',
                warning: '',
            },
        },
        progress: {
            color: {
                secondary: '',
                success: '',
                danger: '',
                warning: '',
            },
        },
    },
})
